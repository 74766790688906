import { Col, Row } from 'antd';
import { Bill } from 'client-sdk';
import { LawEditor } from 'law-document';
import { FC, useEffect, useMemo } from 'react';
import { Descendant } from 'slate';
import { Editable, Slate } from 'slate-react';
import './Editor.css';
import { useEditorConfig } from './EditorConfig';
import EditorSidePanel from './EditorSidePanel';
import HoveringToolbar from './Toolbar/HoverToolbar';
import SideToolbar from './Toolbar/SideToolbar';
import Toolbar from './Toolbar/Toolbar';
import createEditorWithPlugins from './plugins/createEditorWithPlugins';
import handleKeyDown from './plugins/handleKeyDown';
import renderElement from './plugins/renderElement';
import renderLeaf from './plugins/renderLeaf';
import useEditorNavigationBlock from './useEditorNaviationBlock';

interface Props {
    slate: Descendant[];
    originalDocument: Descendant[];
    xml: string;
    readOnly?: boolean;
    saveDocument?: (editor: LawEditor) => void;
    bill?: Bill;
}

const Editor: FC<Props> = ({
    slate,
    originalDocument,
    xml,
    readOnly,
    saveDocument,
    bill,
}) => {
    const hasHighlight = useEditorConfig(state => state.highlightStructure);
    const editor = useMemo(() => createEditorWithPlugins(), []);
    const { handleChange, handleSave } = useEditorNavigationBlock(editor, saveDocument);

    useEffect(() => {
        editor.children = slate;
        editor.onChange();
    }, [slate, editor]);

    const classNames = [
        'editor',
        hasHighlight ? 'highlighted' : ''
    ].join(' ');

    return (
        <Slate editor={editor} initialValue={slate} onChange={handleChange}>
            <div style={{ height: 'calc(100vh - 104px)' }}>
                <Row gutter={16} style={{ height: '100%' }}>
                    <Col span={12} style={{ height: '100%' }}>
                        <div style={{ height: '100%' }}>
                            {readOnly ? null : (
                                <>
                                    <HoveringToolbar />
                                    <SideToolbar />
                                </>
                            )}
                            <Editable
                                className={classNames}
                                onKeyDown={(event) => handleKeyDown(editor, event)}
                                renderElement={renderElement}
                                renderLeaf={renderLeaf}
                                readOnly={readOnly}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        { readOnly ? null : <Toolbar saveDocument={handleSave} bill={bill} /> }
                        <EditorSidePanel
                            readOnly={readOnly}
                            xml={xml}
                            originalDocument={originalDocument}
                        />
                    </Col>
                </Row>
            </div>
        </Slate>
    );
};

export default Editor;
