.editor {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.actions {
    display: flex;
    padding: 10px 20px;
    padding-top: 5px;
    padding-left: 5px;
    gap: 10px;
}

.content {
    overflow: auto;
}